<template>
  <div class="d-flex justify-content-between">
    <div class="iq-header-title">
      <h5 class="card-title">
        <i v-if="headerIcon && headerIcon.length > 0" :class="headerIcon"> </i>  {{ header }}
      </h5>
    </div>
    <div class="iq-card-header-toolbar d-flex align-items-center">
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <b-dropdown v-if="!disableRangeSelection" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <font-awesome-icon icon="fa-regular fa-ellipsis" />
          </template>
          <b-dropdown-item v-for="option in options" :key="option.id" @click="dateSelected = option.id">
            {{ option.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      header: String,
      headerIcon: String,
      disableRangeSelection: Boolean
    },
    data() {
      return {
        dateSelected: '',
        options: [
          { id: 'thisMonth', name: this.$t("ThisMonth") },
          { id: 'thisYear', name: this.$t("ThisYear") }
        ]
      }
    },
    watch: {
      dateSelected: function (val) {
        console.log(val);
      }
    }
  }
</script>
